export const SERVICES = {
  BAGASARADGAM: "/bagasaraDham",
  _PUBLIC: "/bagasaraDham/public",
  GETWAY: "/gateway/admin",
  WHITE_LABEL_DOMAIN: "/api/whiteLabeledData",
};

export const V1 = "/api/v1";
export const API = "/api";

/** LOGIN */

export const LOGIN_ = `${SERVICES._PUBLIC}${V1}/login`;

/** LOGIN */

/**CHNAGE PASS AND EMAIL */
export const CHNAGE_PASS = `${API}/changePasswordFromEmail`;
export const CHNAGE_EMAIL = `${API}/changeOrganizationEmailId`;
/**CHNAGE PASS AND EMAIL */

/** USER_LIST */

export const USER_LIST = `${SERVICES.BAGASARADGAM}${V1}/user`;

/** USER_LIST */

/** SLIDER MASTER*/
export const SLIDER_LIST = `${SERVICES.BAGASARADGAM}${V1}/slider`;
export const SLIDER_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/slider/enableDisable`;
export const ADD_SLIDER = `${SERVICES.BAGASARADGAM}${V1}/slider`;
export const EDIT_SLIDER = `${SERVICES.BAGASARADGAM}${V1}/slider`;
export const SLIDER_DITAILS = `${SERVICES.BAGASARADGAM}${V1}/slider/detail`;
export const SLIDER_DELETE = `${SERVICES.BAGASARADGAM}${V1}/slider`;
export const FILE = `${SERVICES._PUBLIC}${V1}/file`;
/** SLIDER MASTER*/

/** DAILY DARSHAN MASTER*/
export const DAILY_DARSHAN_LIST = `${SERVICES.BAGASARADGAM}${V1}/dailyDarshan`;
export const DAILY_DARSHAN_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/dailyDarshan/enableDisable`;
export const ADD_DAILY_DARSHAN = `${SERVICES.BAGASARADGAM}${V1}/dailyDarshan`;
export const DAILY_DARSHAN_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/dailyDarshan/detail`;
export const DAILY_DARSHAN_DELETE = `${SERVICES.BAGASARADGAM}${V1}/dailyDarshan`;
export const Edit_DAILY_DARSHAN = `${SERVICES.BAGASARADGAM}${V1}/dailyDarshan`;
/** DAILY DARSHAN MASTER*/

/** DAILY DARSHAN MASTER*/
export const PARIVAR_SABHA_LIST = `${SERVICES.BAGASARADGAM}${V1}/parivarSabha`;
export const PARIVAR_SABHA_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/parivarSabha/enableDisable`;
export const ADD_PARIVAR_SABHA = `${SERVICES.BAGASARADGAM}${V1}/parivarSabha`;
export const Edit_PARIVAR_SABHA = `${SERVICES.BAGASARADGAM}${V1}/parivarSabha`;
export const PARIVAR_SABHA_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/parivarSabha/detail`;
export const PARIVAR_SABHA_DELETE = `${SERVICES.BAGASARADGAM}${V1}/parivarSabha`;
/** DAILY DARSHAN MASTER*/

/** EVENTS*/
export const EVENT_LIST = `${SERVICES.BAGASARADGAM}${V1}/event/master`;
export const EVENT_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/event/master/enableDisable`;
export const ADD_EVENT = `${SERVICES.BAGASARADGAM}${V1}/event/master`;
export const Edit_EVENT = `${SERVICES.BAGASARADGAM}${V1}/event/master`;
export const EVENT_DELETE = `${SERVICES.BAGASARADGAM}${V1}/event/master`;
export const EVENT_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/event/detail`;
/** EVENTS*/

/** EVENTS_MASTER_DATA*/
export const EVENT_Data_LIST = `${SERVICES.BAGASARADGAM}${V1}/event`;
export const EVENT_DATA_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/event/enableDisable`;
export const ADD_EVENT_DATA = `${SERVICES.BAGASARADGAM}${V1}/event`;
export const Edit_EVENT_DATA = `${SERVICES.BAGASARADGAM}${V1}/event`;
export const EVENT_DATA_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/event/detail`;
export const EVENT_DATA_DELETE = `${SERVICES.BAGASARADGAM}${V1}/event`;
/** EVENTS_MASTER_DATA*/

/** ACTIVITI*/
export const ACTIVITI_LIST = `${SERVICES.BAGASARADGAM}${V1}/activity/master`;
export const ACTIVITI_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/activity/master/enableDisable`;
export const ADD_ACTIVITI = `${SERVICES.BAGASARADGAM}${V1}/activity/master`;
export const ACTIVITI_EDIT = `${SERVICES.BAGASARADGAM}${V1}/activity/master`;
export const ACTIVITI_DELETE = `${SERVICES.BAGASARADGAM}${V1}/activity/master`;
export const ACTIVITI_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/activity/master/detail`;
/** ACTIVITI*/

/**TYPE MASTER */
export const TYPE_MASTER_LIST = `${SERVICES.BAGASARADGAM}${V1}/typeMaster`;
export const TYPE_MASTER_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/typeMaster/enableDisable`;
export const ADD_TYPE_MASTER = `${SERVICES.BAGASARADGAM}${V1}/typeMaster`;
export const TYPE_MASTER_EDIT = `${SERVICES.BAGASARADGAM}${V1}/typeMaster`;
export const TYPE_MASTER_DELETE = `${SERVICES.BAGASARADGAM}${V1}/typeMaster`;
export const TYPE_MASTER_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/typeMaster/detail`;
/**TYPE MASTER */

/** ACTIVITI_MASTER_DATA*/
export const ACTIVITI_Data_LIST = `${SERVICES.BAGASARADGAM}${V1}/activity`;
export const ACTIVITI_DATA_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/activity/enableDisable`;
export const ADD_ACTIVITI_DATA = `${SERVICES.BAGASARADGAM}${V1}/activity`;
export const Edit_ACTIVITI_DATA = `${SERVICES.BAGASARADGAM}${V1}/activity`;
export const ACTIVITI_DATA_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/activity/detail`;
export const ACTIVITI_DATA_DELETE = `${SERVICES.BAGASARADGAM}${V1}/activity`;
/** ACTIVITI_MASTER_DATA*/

/** SUB_ACTIVITI*/
export const SUB_ACTIVITI_LIST = `${SERVICES.BAGASARADGAM}${V1}/activity/sub`;
export const SUB_ACTIVITI_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/activity/sub/enableDisable`;
export const ADD_SUB_ACTIVITI = `${SERVICES.BAGASARADGAM}${V1}/activity/sub`;
export const Edit_SUB_ACTIVITI = `${SERVICES.BAGASARADGAM}${V1}/activity/sub`;
export const SUB_ACTIVITI_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/activity/sub/detail`;
export const SUB_ACTIVITI_DELETE = `${SERVICES.BAGASARADGAM}${V1}/activity/sub`;
/** SUB_ACTIVITI*/

/** SUB_ACTIVITI*/
export const SUB_TO_SUB_ACTIVITI_LIST = `${SERVICES.BAGASARADGAM}${V1}/activity/subTo`;
export const SUB_TO_SUB_ACTIVITI_ENABLE_DESABLE = `${SERVICES.BAGASARADGAM}${V1}/activity/subTo/enableDisable`;
export const ADD_SUB_TO_SUB_ACTIVITI = `${SERVICES.BAGASARADGAM}${V1}/activity/subTo`;
export const Edit_SUB_TO_SUB_ACTIVITI = `${SERVICES.BAGASARADGAM}${V1}/activity/subTo`;
export const SUB_TO_SUB_ACTIVITI_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/activity/subTo/detail`;
export const SUB_TO_SUB_ACTIVITI_DELETE = `${SERVICES.BAGASARADGAM}${V1}/activity/subTo`;
/** SUB_ACTIVITI*/

/** PACKAGES*/
export const PACKAGES_LIST = `${SERVICES.BAGASARADGAM}${V1}/package_master`;
export const ADD_PACKAGES = `${SERVICES.BAGASARADGAM}${V1}/package_master`;
export const EDIT_PACHAGES = `${SERVICES.BAGASARADGAM}${V1}/package_master`;
export const PACKAGE_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/package_master/detail`;

/** PACKAGES*/

/** SHIPPING MASTER*/
export const SHIPPING_LIST = `${SERVICES.BAGASARADGAM}${V1}/shipping_company_master`;
export const SHIPPING_ADD = `${SERVICES.BAGASARADGAM}${V1}/shipping_company_master`;
export const SHIPPING_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/shipping_company_master/detail`;
export const EDIT_SHIPPING = `${SERVICES.BAGASARADGAM}${V1}/shipping_company_master`;
/** SHIPPING MASTER*/

/** ORDER STATUS LIST*/
export const ORDER_STATUS_LIST = `${SERVICES.BAGASARADGAM}${V1}/order_status_master`;
export const ADD_ORDER_STATUS = `${SERVICES.BAGASARADGAM}${V1}/order_status_master`;
export const ORDER_STATUS_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/order_status_master/detail`;
export const EDIT_ORDER_STATUS = `${SERVICES.BAGASARADGAM}${V1}/order_status_master`;
/** ORDER STATUS LIST*/

/** QR ORDER*/
export const QR_LIST = `${SERVICES.BAGASARADGAM}${V1}/qr_order`;
export const QR_REGUNRATE = `${SERVICES.BAGASARADGAM}${V1}/qr_order/re-generate-order`;
export const CREATE_QR = `${SERVICES.BAGASARADGAM}${V1}/qr_order`;
export const ALL_QR = `${SERVICES.BAGASARADGAM}${V1}/qr_order/generated-qr-list`;
export const OR_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BAGASARADGAM}${V1}/qr_order`;
export const QR_ORDER_LIST_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/qr_order/detail`;
/** QR ORDER*/

/** NFC ORDER*/
export const NFC_ORDER = `${SERVICES.BAGASARADGAM}${V1}/nfc_order`;
export const NFC_LIST = `${SERVICES.BAGASARADGAM}${V1}/nfc_order/generated-nfc-list`;
export const CREATE_NFC = `${SERVICES.BAGASARADGAM}${V1}/nfc_order`;
export const NFC_REGENARATE = `${SERVICES.BAGASARADGAM}${V1}/nfc_order/re-generate-order`;
/** NFC ORDER*/

/** DASHBOARD*/
export const SUPER_ADMIN_DESHBOARD = `${SERVICES.BAGASARADGAM}${V1}/super_admin_dashboard/detail`;
export const SELLER_DESHBOARD = `${SERVICES.BAGASARADGAM}${V1}/seller_dashboard/detail`;
export const NFC__LIST_STATUS_UPDATE = `${SERVICES.BAGASARADGAM}${V1}/nfc_order/status`;
export const NFC_LIST_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/nfc_order/generated-nfc-detail`;
export const NFC_ORDER_LIST_DETAILS = `${SERVICES.BAGASARADGAM}${V1}/nfc_order/detail`;
export const NFC_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BAGASARADGAM}${V1}/nfc_order`;
/** DASHBOARD*/

/** SELLER PASSWORD CHANGE */
export const PASSWORD_CHANGE = `${SERVICES.BAGASARADGAM}${V1}/user/change-password`;
/** SELLER PASSWORD CHANGE */

/** DOWNLOAD QR  */
export const DOWNLOAD_QR = `${SERVICES.BAGASARADGAM}${V1}/qr_order/qrGeneratedDetails`;
export const DOWNLOAD_NFC = `${SERVICES.BAGASARADGAM}${V1}/nfc_order/nfcGeneratedDetails`;
/** DOWNLOAD QR  */

/** PRIVILLAGE MASTER LIST*/
export const PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const ADD_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const EDIT_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DELETE_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DETAILS_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege/detail`;
export const MODULE_ACCESS_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/access`;
/** PRIVILLAGE MASTER LIST*/

/** MODUAL MASTER LIST*/
export const MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const ADD_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DETAILS_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/detail`;
export const EDIT_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DELETE_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
/** MODUAL MASTER LIST*/

/**WHITE LABEL DOMAIN API */
export const GET_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/getAll`;
export const POST_CREATE_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/add`;
export const PUT_EDIT_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/update`;
/**WHITE LABEL DOMAIN API */
