import axios from "axios";
import {
  ACTIVITI_DATA_DELETE,
  ACTIVITI_DATA_DETAILS,
  ACTIVITI_DATA_ENABLE_DESABLE,
  ACTIVITI_Data_LIST,
  ADD_ACTIVITI_DATA,
  Edit_ACTIVITI_DATA,
  FILE,
} from "../../constant/API constant";

export const Activiti_Data_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ACTIVITI_Data_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Activiti_Data_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ACTIVITI_DATA_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Activiti_Data = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ACTIVITI_DATA}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Data_Activiti = (formData, activityId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${Edit_ACTIVITI_DATA}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { activityId },
    }
  );
};

export const Activiti_Data_Details = (activityId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ACTIVITI_DATA_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { activityId },
  });
};

export const Activiti_Data_Delete = (activityId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${ACTIVITI_DATA_DELETE}`,
    {
      headers: { Authorization: jwt_token },
      params: { activityId },
    }
  );
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
