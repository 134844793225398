import React, { useContext, useEffect, useState } from "react";

import ActivitiMasterDataItem from "./ActivitiMasterDataItem";
import ActivitiMasterDataAddModal from "./ActivitiMasterDataAddModal";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { Image_File_tye } from "../../constant";

import moment from "moment";
import { FILE_API } from "../../api/Parivar Sabha Api/ParivarSabhaApi";
import ActivitiModualContext from "../../context/ActivitiModualContext/ActivitiModualContext";
import ActivitiMasterDataContext from "../../context/ActivitiMasterDataContext/ActivitiMasterDataContext";
import {
  Activiti_Data_Delete,
  Activiti_Data_Details,
  Activiti_Data_Enable_Desable,
  Activiti_Data_List,
  Add_Activiti_Data,
  Edit_Data_Activiti,
} from "../../api/ActivitiMasterDataApi/ActivitiMasterDataApi";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import ChropeImage from "../SliderMaster/ChropeImage";

const ActivitiMasterData = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    isSwitchOn,
    setIsSwitchOn,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,

    setLink,
    image,
    setImage,
    setEventMasterId,
    name,
    setName,
    alt,
    setAlt,
    date,
    setDate,
    details,
    setDetails,
    templeId,
    setTempleId,
    eventId,
    setEventId,
    link,
    imageUri,
    setType,
    type,
    activityId,
    setActivityId,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    imageModal,
    setImageModal,
  } = useContext(ActivitiMasterDataContext);

  const { eventMasterId, activityMasterId } = useContext(ActivitiModualContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  /**
   * Slider Master list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: {
          activityMasterId:
            activityMasterId || localStorage.getItem("activityMasterId"),
        },
      };
      if (filter.length) {
        paramsObj["search"] = {
          name: filter,
          activityMasterId:
            activityMasterId || localStorage.getItem("activityMasterId"),
        };
      }
      const { data } = await Activiti_Data_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    localStorage.removeItem("activityId");
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.onload = (_event) => {
        setImage(reader.result?.toString() || "");
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setImageModal(true);
  };

  const ImageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", Image_File_tye.ACTIVITY);
    formData.append("file", file);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.baseUrl + data.payload.imageUrl);
        setImageModal(false);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  /**
   * edit Slider Master function call
   */

  const editHandle = async (activityId) => {
    setActivityId(activityId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Activiti_Data_Details(activityId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(activityId);
        setModal(true);

        setImage(data.payload.data.link);
        setName(data.payload.data.name);
        setAlt(data.payload.data.alt);
        setLink(data.payload.data.link);
        setType(data.payload.data.type);

        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setImage("");
    setName("");
    setAlt("");
    setDate("");
    setEventMasterId("");
    setDetails("");
    setTempleId("");
    setEventId("");
    setLink("");
    setType("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setImage(image);
    setName(name);
    setAlt(alt);
    setDate(date);
    setEventMasterId(eventMasterId);
    setDetails(details);
    setTempleId(templeId);
    setEventId(eventId);
    setLink(link);
    setType(type);
    setLoaderShow(false);
  };

  /**
   * create and edit function call SLider Master
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex > -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);

      formData = {
        activityMasterId:
          +activityMasterId || +localStorage.getItem("activityMasterId"),
        image: image ? image : imageUri,
        link: image ? image : link,
        name,
        alt,
        type: type, // type 1 (video) & type 2 (image)
      };

      try {
        const { data } = await Edit_Data_Activiti(formData, activityId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        activityMasterId:
          +activityMasterId || +localStorage.getItem("activityMasterId"),
        image: image ? image : imageUri,
        link: image ? image : link,
        name,
        alt,
        type: type, // type 1 (video) & type 2 (image)
      };

      try {
        const { data } = await Add_Activiti_Data(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (activityId) => {
    setActivityId(activityId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Activiti_Data_Details(activityId);
      if (data.status === 200) {
        setahoeDataModel(true);
        let payload = data.payload.data;
        setLink(payload ? payload.link : "");
        setName(payload ? payload.name : "");
        setDate(payload ? moment(payload.date).format("DD-MM-YYYY") : "");
        setDetails(payload ? payload.details : "");
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteHandle = async (activityId) => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Activiti_Data_Delete(activityId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (activityId, status) => {
    let formData = {
      activityId: activityId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Activiti_Data_Enable_Desable(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  const redirectToAddSubActivity = (activityId) => {
    setActivityId(activityId);
    localStorage.setItem("activityId", activityId);
    history(`${process.env.PUBLIC_URL}/SubActivity/SubActivity/${layout}`);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <ActivitiMasterDataAddModal
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
        />
        <ActivitiMasterDataItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          deleteHandle={deleteHandle}
          redirectToAddSubActivity={redirectToAddSubActivity}
        />
        <ChropeImage
          ImageUpload={ImageUpload}
          imageWidth={66}
          aspect={16 / 9}
          imageModal={imageModal}
          setImageModal={setImageModal}
          image={image}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
        />
      </div>
    </>
  );
};
export default ActivitiMasterData;
