import axios from "axios";
import {
  ADD_EVENT,
  EVENT_DELETE,
  EVENT_DETAILS,
  EVENT_ENABLE_DESABLE,
  EVENT_LIST,
  Edit_EVENT,
  FILE,
} from "../../constant/API constant";

export const Event_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${EVENT_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Event_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EVENT_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Event = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${ADD_EVENT}`, formData, {
    headers: { Authorization: jwt_token },
  });
};

export const Edit_Event = (formData, eventMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${Edit_EVENT}`, formData, {
    headers: { Authorization: jwt_token },
    params: { eventMasterId },
  });
};

export const Event_Details = (eventMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${EVENT_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { eventMasterId },
  });
};

export const Event_Delete = (eventMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${EVENT_DELETE}`, {
    headers: { Authorization: jwt_token },
    params: { eventMasterId },
  });
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
