import React, { useContext, useEffect, useState } from "react";

import EventModualItem from "./EventModualItem";
import EventModualAddModal from "./EventModualAddModal";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { Image_File_tye } from "../../constant";

import moment from "moment";
import { FILE_API } from "../../api/Parivar Sabha Api/ParivarSabhaApi";
import EventModualContext from "../../context/EventModualContext/EventModualContext";
import {
  Add_Event,
  Edit_Event,
  Event_Delete,
  Event_Details,
  Event_Enable_Desable,
  Event_List,
} from "../../api/EventApi copy/EventApi";
import { useNavigate } from "react-router";
import { classes } from "../../data/layouts";
import ChropeImage from "../SliderMaster/ChropeImage";

const EventModual = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    isSwitchOn,
    setIsSwitchOn,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setBaseUrl,

    setLink,
    image,
    setImage,
    eventMasterId,
    setEventMasterId,
    name,
    setName,
    alt,
    setAlt,
    date,
    setDate,
    details,
    setDetails,
    templeId,
    setTempleId,
    type,
    setType,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    imageModal,
    setImageModal,
  } = useContext(EventModualContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  /**
   * Slider Master list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await Event_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    localStorage.removeItem("eventMasterId");
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.onload = (_event) => {
        setImage(reader.result?.toString() || "");
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setImageModal(true);
  };

  const ImageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", Image_File_tye.EVENT);
    formData.append("file", file);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.baseUrl + data.payload.imageUrl);
        setImageModal(false);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  /**
   * edit Slider Master function call
   */

  const editHandle = async (
    eventMasterId,
    image,
    name,
    alt,
    date,
    details,
    templeId,
    type
  ) => {
    setEventMasterId(eventMasterId);
    setImage(image);
    setName(name);
    setAlt(alt);
    setDate(moment(date).format("YYYY-MM-DD"));
    setDetails(details);
    setTempleId(templeId);
    setType(type);
    setToggle(false);
    setIsAddHandle(false);
    seteditIndex(eventMasterId);
    setModal(true);
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setImage("");
    setName("");
    setAlt("");
    setDate("");
    setEventMasterId("");
    setDetails("");
    setTempleId("");
    setType("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setImage(image);
    setName(name);
    setAlt(alt);
    setDate(date);
    setEventMasterId(eventMasterId);
    setDetails(details);
    setTempleId(templeId);
    setType(type);
    setLoaderShow(false);
  };

  /**
   * create and edit function call SLider Master
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;

    if (editIndex > -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);
      let logo = image.split("bagasara-dham/").pop();
      formData = {
        image: logo,
        name,
        alt,
        date,
        details,
        templeId,
        type: 1,
      };

      try {
        const { data } = await Edit_Event(formData, eventMasterId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      let logo = image.split("bagasara-dham/").pop();
      formData = {
        image: logo,
        name,
        alt,
        date,
        details,
        templeId,
        type: 1,
      };

      try {
        const { data } = await Add_Event(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (eventMasterId) => {
    setEventMasterId(eventMasterId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Event_Details(eventMasterId);
      if (data.status === 200) {
        setahoeDataModel(true);
        let payload = data.payload.data;
        setLink(payload ? payload.link : "");
        setName(payload ? payload.name : "");
        setDate(payload ? moment(payload.date).format("DD-MM-YYYY") : "");
        setDetails(payload ? payload.details : "");
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteHandle = async (eventMasterId) => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Event_Delete(eventMasterId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (eventMasterId, status) => {
    let formData = {
      eventMasterId: eventMasterId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Event_Enable_Desable(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  const redirectToAddEvents = (eventMasterId) => {
    setEventMasterId(eventMasterId);
    localStorage.setItem("eventMasterId", eventMasterId);
    history(
      `${process.env.PUBLIC_URL}/EventMasterData/EventMasterData/${layout}`
    );
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <EventModualAddModal
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
        />
        <EventModualItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          deleteHandle={deleteHandle}
          redirectToAddEvents={redirectToAddEvents}
        />

        <ChropeImage
          ImageUpload={ImageUpload}
          imageWidth={66}
          aspect={15 / 10}
          imageModal={imageModal}
          setImageModal={setImageModal}
          image={image}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
        />
      </div>
    </>
  );
};
export default EventModual;
