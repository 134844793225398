import React, { useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDebounceEffect } from "../../utils/useDebounceEffect";
import { canvasPreview } from "../../utils/canvasPriview";

function centerAspectCrop(mediaWidth, mediaHeight, aspect, imageWidth) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: imageWidth,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ChropeImage = (props) => {
  const {
    ImageUpload,
    imageWidth,
    aspect,
    imageModal,
    setImageModal,
    image,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = props;
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect, imageWidth));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSetImage = async () => {
    debugger;
    const canvas = previewCanvasRef.current;
    const imageUri = canvas.toDataURL("image/jpeg", 0.5);
    const blobImage = dataURLtoBlob(imageUri);
    const file = new File([blobImage], "default.jpg", { type: blobImage.type });
    await ImageUpload(file);
  };

  return (
    <Modal size="xl" isOpen={imageModal}>
      <ModalHeader toggle={() => setImageModal(!imageModal)}>
        <span>{"Image Crop"}</span>
      </ModalHeader>
      <ModalBody>
        {/* <Input type="file" accept="image/*" onChange={onSelectFile} /> */}
        {!!image && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={image}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <Button
          onClick={() => handleSetImage()}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          Crop
        </Button>

        {!!completedCrop && (
          <>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
            <div>
              {/* <button onClick={onDownloadCropClick}>Download Crop</button> */}
              <a
                ref={hiddenAnchorRef}
                download
                style={{
                  position: "absolute",
                  top: "-200vh",
                  visibility: "hidden",
                }}
              >
                Hidden download
              </a>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChropeImage;
