import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Edit, Plus, Trash } from "react-feather";
import Switch from "react-js-switch";

import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { MuiTabelStyle, SUB_TO_SUB_ACTIVITI } from "../../../../constant";
import DataNotFound from "../../../../DataNoteFound/DataNotFound";

import Breadcrumbs from "../../../../layout/breadcrumb";
import moment from "moment";
import SubToSubActivityContext from "../../../../context/SubToSubActivityContext/SubToSubActivityContext";
import { classes } from "../../../../data/layouts";
import { useNavigate } from "react-router";

const SubToSubActivityItem = (props) => {
  const {
    editHandle,
    sorting,
    switch_onChange_handle,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    deleteHandle,
  } = props;

  const { filter, formvalue, setFilter } = useContext(SubToSubActivityContext);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "type",
      headerName: "Type",
      renderCell: (params) => (params.row.type === 1 ? "Photos" : "-"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "-"),
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "isActive",
      headerName: "STATUS",
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(
              params.row.subToSubActivityId,
              params.row.isActive
            )
          }
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "date",
      headerName: "Date",
      renderCell: (params) => moment(params.row.date).format("DD-MM-YYYY"),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 130,
      renderCell: (params) => (
        <>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() =>
              editHandle(
                params.row.subToSubActivityId,
                params.row.image,
                params.row.name,
                params.row.alt,
                params.row.link,
                params.row.date,
                params.row.details,
                params.row.templeId,
                params.row.type
              )
            }
          >
            <Edit style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "red",
            }}
            onClick={() => deleteHandle(params.row.subToSubActivityId)}
          >
            <Trash style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  const handleBack = () => {
    history(`${process.env.PUBLIC_URL}/SubActivity/SubActivity/${layout}`);
  };

  return (
    <Fragment>
      <Breadcrumbs parent={SUB_TO_SUB_ACTIVITI.PERENT} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3>{SUB_TO_SUB_ACTIVITI.PERENT}</h3>
                <button
                  type="button"
                  style={{ border: "none", background: "none" }}
                  onClick={AddModel}
                >
                  <div
                    style={{
                      width: "50px",
                      background: "#7366ff",
                      borderRadius: "19rem",
                      color: "white",
                    }}
                  >
                    <h1>
                      <Plus />
                    </h1>
                  </div>
                </button>
              </CardHeader>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Row>
                        <Col md="1 mb-2">
                          <Button color="danger" onClick={() => handleBack()}>
                            Back
                          </Button>
                        </Col>
                        <Col md="2 mb-2">
                          <Form className="search-file">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Sub Activity Name Search..."
                              value={filter}
                              onInput={(e) => setFilter(e.target.value)}
                              style={{
                                border: "1px solid #ecf3fa",
                                borderRadius: "8px",
                              }}
                            />
                          </Form>
                        </Col>
                      </Row>
                    </th>
                  </tr>
                </thead>
              </Table>
              <Box
                sx={{
                  "& .even": {
                    backgroundColor: "#f1f1f1",
                  },
                  "& .odd": {
                    backgroundColor: "white",
                  },
                }}
              >
                {formvalue.length && formvalue.length ? (
                  <>
                    <DataGrid
                      autoHeight
                      pagination
                      sx={MuiTabelStyle}
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.subToSubActivityId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  </>
                ) : (
                  <DataNotFound />
                )}
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SubToSubActivityItem;
