import axios from "axios";
import {
  ADD_DAILY_DARSHAN,
  DAILY_DARSHAN_DELETE,
  DAILY_DARSHAN_DETAILS,
  DAILY_DARSHAN_ENABLE_DESABLE,
  DAILY_DARSHAN_LIST,
  Edit_DAILY_DARSHAN,
  FILE,
} from "../../constant/API constant";

export const Daily_Darshan_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DAILY_DARSHAN_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Daily_Darshan_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${DAILY_DARSHAN_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Daily_Darshan = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_DAILY_DARSHAN}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Daily_Darshan = (formData, dailyDarshanId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${Edit_DAILY_DARSHAN}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { dailyDarshanId },
    }
  );
};

export const Daily_Darshan_Details = (dailyDarshanId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DAILY_DARSHAN_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { dailyDarshanId },
  });
};

export const Daily_Darshan_Delete = (dailyDarshanId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DAILY_DARSHAN_DELETE}`,
    {
      headers: { Authorization: jwt_token },
      params: { dailyDarshanId },
    }
  );
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
