import React, { useContext, useEffect, useState } from "react";

import EventMasterDataItem from "./EventMasterDataItem";
import EventMasterDataAddModal from "./EventMasterDataAddModal";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { Image_File_tye } from "../../constant";

import moment from "moment";
import { FILE_API } from "../../api/Parivar Sabha Api/ParivarSabhaApi";
import EventModualContext from "../../context/EventModualContext/EventModualContext";
import {
  Add_Event_Data,
  Edit_Data_Event,
  Event_Data_Delete,
  Event_Data_Details,
  Event_Data_Enable_Desable,
  Event_Data_List,
} from "../../api/EventMasterDataApi/EventMasterDataApi";
import EventMasterDataContext from "../../context/EventMasterDataContext/EventMasterDataContext";
import ChropeImage from "../SliderMaster/ChropeImage";

const EventMasterData = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    isSwitchOn,
    setIsSwitchOn,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,

    setLink,
    image,
    setImage,
    setEventMasterId,
    name,
    setName,
    alt,
    setAlt,
    date,
    setDate,
    details,
    setDetails,
    templeId,
    setTempleId,
    eventId,
    setEventId,
    link,
    setType,
    type,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    imageModal,
    setImageModal,
  } = useContext(EventMasterDataContext);

  const { eventMasterId } = useContext(EventModualContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * Slider Master list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: {
          eventMasterId: eventMasterId,
        },
      };
      if (filter.length) {
        paramsObj["search"] = {
          name: filter,
          eventMasterId: eventMasterId,
        };
      }
      const { data } = await Event_Data_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.onload = (_event) => {
        setImage(reader.result?.toString() || "");
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setImageModal(true);

    // let formData = new FormData();
    // formData.append("type", Image_File_tye.EVENT);
    // formData.append("file", event.target.files[0]);
    // setLoaderShow(!loaderShow);
    // try {
    //   const { data } = await FILE_API(formData);
    //   if (data.status === 200) {
    //     toast.success(data.message, {
    //       autoClose: 1000,
    //       transition: Zoom,
    //     });
    //     setImage(data.payload.imageUrl);
    //     setLoaderShow(false);
    //   } else {
    //     setformvalue([]);
    //     setLoaderShow(false);
    //   }
    // } catch (error) {
    //   toast.error(error.response.data.message, {
    //     autoClose: 1000,
    //     transition: Zoom,
    //   });
    //   setLoaderShow(false);
    // }
  };

  const ImageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", Image_File_tye.EVENT);
    formData.append("file", file);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.baseUrl + data.payload.imageUrl);
        setImageModal(false);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  /**
   * edit Slider Master function call
   */

  const editHandle = async (eventsId) => {
    setEventId(eventsId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Event_Data_Details(eventsId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(eventsId);
        setModal(true);

        setImage(data.payload.data.link);
        setName(data.payload.data.name);
        setAlt(data.payload.data.alt);
        setLink(data.payload.data.link);
        setType(data.payload.data.type);

        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setImage("");
    setName("");
    setAlt("");
    setDate("");
    setEventMasterId("");
    setDetails("");
    setTempleId("");
    setEventId("");
    setLink("");
    setType("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setImage(image);
    setName(name);
    setAlt(alt);
    setDate(date);
    setEventMasterId(eventMasterId);
    setDetails(details);
    setTempleId(templeId);
    setEventId(eventId);
    setLink(link);
    setType(type);
    setLoaderShow(false);
  };

  /**
   * create and edit function call SLider Master
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex > -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);
      let logo = image.split("bagasara-dham/").pop();
      formData = {
        eventMasterId: eventMasterId || localStorage.getItem("eventMasterId"),
        image: logo,
        link: link ? link : image,
        name,
        alt,
        type: type, // type 1 (video) & type 2 (image)
      };

      try {
        const { data } = await Edit_Data_Event(formData, eventId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      let logo = image.split("bagasara-dham/").pop();
      formData = {
        eventMasterId: eventMasterId,
        image: logo,
        link: link ? link : image,
        name,
        alt,
        type: type, // type 1 (video) & type 2 (image)
      };

      try {
        const { data } = await Add_Event_Data(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (eventsId) => {
    setEventMasterId(eventsId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Event_Data_Details(eventsId);
      if (data.status === 200) {
        setahoeDataModel(true);
        let payload = data.payload.data;
        setLink(payload ? payload.link : "");
        setName(payload ? payload.name : "");
        setDate(payload ? moment(payload.date).format("DD-MM-YYYY") : "");
        setDetails(payload ? payload.details : "");
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteHandle = async (eventsId) => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Event_Data_Delete(eventsId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (eventsId, status) => {
    let formData = {
      eventsId: eventsId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Event_Data_Enable_Desable(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <EventMasterDataAddModal
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
        />
        <EventMasterDataItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          deleteHandle={deleteHandle}
        />
        <ChropeImage
          ImageUpload={ImageUpload}
          imageWidth={66}
          aspect={15 / 10}
          imageModal={imageModal}
          setImageModal={setImageModal}
          image={image}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
        />
      </div>
    </>
  );
};
export default EventMasterData;
