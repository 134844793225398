import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, DAILY_DARSHAN, TEMPLE, TYPES } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import DailyDarshanContext from "../../context/DailyDarshanMasterContext/DailyDarshanMasterContext";

const OrganizationAddModel = (props) => {
  const { onSubmit, closeModel, readUrl } = props;
  const {
    modal,
    name,
    setName,
    togglesubmit,
    alt,
    setAlt,
    type,
    setType,
    templeId,
    setTempleId,
    image,
    setImage,
    date,
    setDate,
  } = useContext(DailyDarshanContext);
  const { loaderShow } = useContext(LoaderContext);

  return (
    <>
      <Modal size="xl" isOpen={modal}>
        <ModalHeader toggle={() => closeModel()}>
          {togglesubmit ? (
            <span>{DAILY_DARSHAN.ADD_DAILY_DARSHAN}</span>
          ) : (
            <span>
              {DAILY_DARSHAN.EDIT_DAILY_DARSHAN} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {DAILY_DARSHAN.DAILY_DARSHAN_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {DAILY_DARSHAN.TYPE}
                        </Label>
                        <select
                          className="form-select"
                          name="state"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {TYPES.map((elem) => {
                            return (
                              <option value={elem.type}>{elem.name}</option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {DAILY_DARSHAN.TEMPLE}
                        </Label>
                        <select
                          className="form-select"
                          name="state"
                          value={templeId}
                          onChange={(e) => setTempleId(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {TEMPLE.map((elem) => {
                            return (
                              <option value={elem.type}>{elem.name}</option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="6 mb-4">
                        {+type === 1 ? (
                          <>
                            <Label htmlFor="validationCustom14">
                              {DAILY_DARSHAN.VIDEO}
                            </Label>
                            <Input
                              className="form-control"
                              name="name"
                              id="validationCustom14"
                              type="url"
                              placeholder="Video"
                              value={image}
                              onChange={(e) => setImage(e.target.value)}
                            />
                          </>
                        ) : (
                          +type === 2 && (
                            <>
                              <Label htmlFor="validationCustom14">
                                {"(724PX) x (1600PX)"}
                              </Label>
                              <div className="user-image">
                                <div className="avatar">
                                  <Media
                                    body
                                    alt=""
                                    src={image}
                                    style={{
                                      width: "9rem",
                                      height: "9rem",
                                      borderRadius: "45rem",
                                      border: "1px solid ",
                                    }}
                                    data-intro="This is Profile image"
                                  />
                                </div>
                                <div
                                  className="icon-wrapper"
                                  data-intro="Change Profile image here"
                                >
                                  <i className="icofont icofont-pencil-alt-5">
                                    <Input
                                      className="upload"
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => readUrl(e)}
                                      style={{
                                        top: "0",
                                        height: "11rem",
                                        width: "11rem",
                                      }}
                                      // required
                                    />
                                  </i>
                                </div>
                              </div>
                            </>
                          )
                        )}
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md={+type === 1 ? "6 mb-4" : "12 mb-4"}>
                        <Label htmlFor="validationCustom14">
                          {DAILY_DARSHAN.NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {DAILY_DARSHAN.ALT}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Alt"
                          onChange={(e) => setAlt(e.target.value)}
                          value={alt}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {DAILY_DARSHAN.DATE}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="date"
                          placeholder="Alt"
                          onChange={(e) => setDate(e.target.value)}
                          value={date}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
