import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import ParivarSabhaContext from "../../context/ParivarSabhaMasterContext/ParivarSabhaMasterContext";

const OrganizationDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, link, name, date, details } =
    useContext(ParivarSabhaContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Parivar Sabha
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{"Link"}</th>
                            <td>{<a href={link}>View</a>}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"name"}</th>
                            <td>{name ? name : "-"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Date"}</th>
                            <td>{date ? date : "-"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Details"}</th>
                            <td>{details ? details : "-"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
