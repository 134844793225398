import axios from "axios";
import {
  ADD_TYPE_MASTER,
  FILE,
  TYPE_MASTER_DELETE,
  TYPE_MASTER_DETAILS,
  TYPE_MASTER_EDIT,
  TYPE_MASTER_ENABLE_DESABLE,
  TYPE_MASTER_LIST,
} from "../../constant/API constant";

export const Type_Master_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${TYPE_MASTER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Type_Master_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${TYPE_MASTER_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Type_Master = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_TYPE_MASTER}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Type_Master = (formData, typeId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${TYPE_MASTER_EDIT}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { typeId },
    }
  );
};

export const Type_Master_Details = (typeId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${TYPE_MASTER_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { typeId },
  });
};

export const Type_Master_Delete = (typeId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${TYPE_MASTER_DELETE}`, {
    headers: { Authorization: jwt_token },
    params: { typeId },
  });
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
