import axios from "axios";
import {
  ADD_EVENT_DATA,
  EVENT_DATA_DELETE,
  EVENT_DATA_DETAILS,
  EVENT_DATA_ENABLE_DESABLE,
  EVENT_Data_LIST,
  Edit_EVENT_DATA,
  FILE,
} from "../../constant/API constant";

export const Event_Data_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${EVENT_Data_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Event_Data_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EVENT_DATA_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Event_Data = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_EVENT_DATA}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Data_Event = (formData, eventsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${Edit_EVENT_DATA}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { eventsId },
    }
  );
};

export const Event_Data_Details = (eventsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${EVENT_DATA_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { eventsId },
  });
};

export const Event_Data_Delete = (eventsId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${EVENT_DATA_DELETE}`, {
    headers: { Authorization: jwt_token },
    params: { eventsId },
  });
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
