import React, { useContext, useEffect, useState } from "react";

import ParivarSabhaMasterItem from "./ParivarSabhaMasterItem";
import ParivarSabhaMasterDetailsModel from "./ParivarSabhaMasterDetailsModel";
import ParivarSabhaMasterAddModal from "./ParivarSabhaMasterAddModal";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { Image_File_tye } from "../../constant";

import moment from "moment";
import {
  Parivar_Sabha_List,
  FILE_API,
  Parivar_Sabha_Enable_Desable,
  Add_Parivar_Sabha,
  Edit_Parivar_Sabha,
  Parivar_Sabha_Details,
  Privar_Sabha_Delete,
} from "../../api/Parivar Sabha Api/ParivarSabhaApi";
import ParivarSabhaContext from "../../context/ParivarSabhaMasterContext/ParivarSabhaMasterContext";

const ParivarSabhaMaster = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    isSwitchOn,
    setIsSwitchOn,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setBaseUrl,
    setImage,
    link,
    setLink,
    sabhaId,
    setsabhaId,
    name,
    setName,
    date,
    setDate,
    details,
    setDetails,
  } = useContext(ParivarSabhaContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * Slider Master list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await Parivar_Sabha_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    setLoaderShow(!loaderShow);
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setImage(reader.result);
    };

    let formData = new FormData();
    formData.append("type", Image_File_tye.DAILY_DARSAN);
    formData.append("file", event.target.files[0]);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.imageUrl);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
        setformvalue([]);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  /**
   * edit Slider Master function call
   */

  const editHandle = async (sabhaId) => {
    setsabhaId(sabhaId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Parivar_Sabha_Details(sabhaId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(sabhaId);
        setModal(true);

        let payload = data.payload.data;

        setLink(payload?.link);
        setName(payload?.name);
        setDate(payload.date ? moment(payload.date).format("YYYY-MM-DD") : "");
        setDetails(payload?.details);

        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setLink("");
    setName("");
    setDate("");
    setDetails("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setLink(link);
    setName(name);
    setDate(date);
    setDetails(details);
    setLoaderShow(false);
  };

  /**
   * create and edit function call SLider Master
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;

    if (editIndex > -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);

      formData = {
        name,
        link,
        details,
        date,
      };

      try {
        const { data } = await Edit_Parivar_Sabha(formData, sabhaId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        name,
        link,
        details,
        date,
      };

      try {
        const { data } = await Add_Parivar_Sabha(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (sabhaId) => {
    setsabhaId(sabhaId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Parivar_Sabha_Details(sabhaId);
      if (data.status === 200) {
        setahoeDataModel(true);
        let payload = data.payload.data;
        setLink(payload ? payload.link : "");
        setName(payload ? payload.name : "");
        setDate(payload ? moment(payload.date).format("DD-MM-YYYY") : "");
        setDetails(payload ? payload.details : "");
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteHandle = async (sabhaId) => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Privar_Sabha_Delete(sabhaId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (sabhaId, status) => {
    let formData = {
      sabhaId: sabhaId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Parivar_Sabha_Enable_Desable(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <ParivarSabhaMasterAddModal
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
        />
        <ParivarSabhaMasterDetailsModel />
        <ParivarSabhaMasterItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          deleteHandle={deleteHandle}
        />
      </div>
    </>
  );
};
export default ParivarSabhaMaster;
