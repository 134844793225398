import * as React from "react";
import { useState } from "react";
import SubToSubActivityContext from "./SubToSubActivityContext";

const SubToSubActivityState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [genFormvalue, setgenFormvalue] = useState([]); //API -->
  const [recordPerPageGenerated, setRecordPerPageGenerated] = useState(0);

  const [showWallet, setShowWallet] = useState(false);
  const [showAddWallet, setShowAddWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);

  const [image, setImage] = useState("");
  const [activityMasterId, setActivityMasterId] = useState("");
  const [name, setName] = useState("");
  const [alt, setAlt] = useState("");
  const [date, setDate] = useState("");
  const [details, setDetails] = useState("");
  const [templeId, setTempleId] = useState("");
  const [type, setType] = useState("");
  const [link, setLink] = useState("");

  const [check, setCheck] = useState(true);
  const [generated, setGenerated] = useState(false);
  const [domain, setDomain] = useState("");
  const [qrDomain, setQrDomain] = useState("");
  const [file, setFile] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState("ASC"); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->

  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  const [count, setCount] = useState(0);

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 5);
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  return (
    <>
      <SubToSubActivityContext.Provider
        value={{
          showWallet,
          setShowWallet,
          formvalue,
          setformvalue,

          name,
          setName,
          image,
          setImage,
          activityMasterId,
          setActivityMasterId,
          alt,
          setAlt,
          details,
          setDetails,
          templeId,
          setTempleId,
          type,
          setType,
          link,
          setLink,

          setahoeDataModel,
          ahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          check,
          setCheck,
          domain,
          setDomain,
          qrDomain,
          setQrDomain,
          file,
          setFile,
          generated,
          setGenerated,
          genFormvalue,
          setgenFormvalue,
          recordPerPageGenerated,
          setRecordPerPageGenerated,
          walletList,
          setWalletList,
          showAddWallet,
          setShowAddWallet,
          date,
          setDate,
          count,
          setCount,

          crop,
          setCrop,
          completedCrop,
          setCompletedCrop,
          scale,
          setScale,
          rotate,
          setRotate,
          aspect,
          setAspect,
          base64,
          setBase64,
          imageModal,
          setImageModal,
        }}
      >
        {props.children}
      </SubToSubActivityContext.Provider>
    </>
  );
};

export default SubToSubActivityState;
