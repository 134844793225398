// dashbaord
import Ecommerce from "../components/dashboard/ecommerce";
import SliderMaster from "../components/SliderMaster/SliderMaster";
import Shippingmaster from "../components/shipingmaster/shipingmaster";
import PrivillageManager from "../components/PrivillageManager/PrivillageManager";
import ModualManager from "../components/ModualManager/ModualManager";
import DailyDarshanMaster from "../components/DailyDarshanMaster/DailyDarshanMaster";
import ParivarSabhaMaster from "../components/ParivarSabhaMaster/ParivarSabhaMaster";
import EventModual from "../components/EventsModual/EventModual";
import EventMasterData from "../components/EventMasterData/EventMasterData";
import ActivitiModual from "../components/ActivitiModual/ActivitiModual";
import ActivitiMasterData from "../components/ActivitiMasterData/ActivitiMasterData";
import SubActivity from "../components/ActivitiMasterData/SubActivity/SubActivity";
import SubToSubActivity from "../components/ActivitiMasterData/SubActivity/SubToSubActivity/SubToSubActivity";
import TypeMaster from "../components/TypeMaster/TypeMaster";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/:layout/`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/TypeMaster/TypeMaster/:layout/`,
    Component: <TypeMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/SliderMaster/SliderMaster/:layout/`,
    Component: <SliderMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/DailyDarshanMaster/DailyDarshanMaster/:layout/`,
    Component: <DailyDarshanMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ParivarSabhaMaster/ParivarSabhaMaster/:layout/`,
    Component: <ParivarSabhaMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/EventsModual/EventModual/:layout/`,
    Component: <EventModual />,
  },
  {
    path: `${process.env.PUBLIC_URL}/EventMasterData/EventMasterData/:layout/`,
    Component: <EventMasterData />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ActivitiModual/ActivitiModual/:layout/`,
    Component: <ActivitiModual />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ActivitiMasterData/ActivitiMasterData/:layout/`,
    Component: <ActivitiMasterData />,
  },
  {
    path: `${process.env.PUBLIC_URL}/SubActivity/SubActivity/:layout/`,
    Component: <SubActivity />,
  },
  {
    path: `${process.env.PUBLIC_URL}/SubToSubActivity/SubToSubActivity/:layout/`,
    Component: <SubToSubActivity />,
  },
  {
    path: `${process.env.PUBLIC_URL}/shipingmaster/shipingmaster/:layout/`,
    Component: <Shippingmaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager/:layout/`,
    Component: <PrivillageManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager/:layout/`,
    Component: <ModualManager />,
  },
];
