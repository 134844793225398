import React, { useContext, useEffect, useState } from "react";

import SubActivityItem from "./SubActivityItem";
import SubActivityAddModal from "./SubActivityAddModal";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import TopLoadingBar from "../../topLoadingBar/topLoadingBar";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import { Image_File_tye } from "../../../constant";

import moment from "moment";
import { FILE_API } from "../../../api/Parivar Sabha Api/ParivarSabhaApi";

import { useNavigate } from "react-router";
import { classes } from "../../../data/layouts";

import SubActivityContext from "../../../context/SubActivityContext/SubActivityContext";
import {
  Add_Sub_Activiti,
  Edit_Sub_Activiti,
  Sub_Activiti_Delete,
  Sub_Activiti_Details,
  Sub_Activiti_Enable_Desable,
  Sub_Activiti_List,
} from "../../../api/SubActivitiApi/SubActivitiApi";
import ActivitiMasterDataContext from "../../../context/ActivitiMasterDataContext/ActivitiMasterDataContext";
import ChropeImage from "../../SliderMaster/ChropeImage";

const SubActivity = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    isSwitchOn,
    setIsSwitchOn,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    link,
    setLink,
    image,
    setImage,
    name,
    setName,
    alt,
    setAlt,
    date,
    setDate,
    details,
    setDetails,
    templeId,
    setTempleId,
    type,
    setType,
    setActivityMasterId,
    activityMasterId,
    setSubActivityId,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    imageModal,
    setImageModal,
  } = useContext(SubActivityContext);

  const { activityId } = useContext(ActivitiMasterDataContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  /**
   * Slider Master list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: {
          activityId: activityId || localStorage.getItem("activityId"),
        },
      };
      if (filter.length) {
        paramsObj["search"] = {
          name: filter,
          activityId: activityId || localStorage.getItem("activityId"),
        };
      }
      const { data } = await Sub_Activiti_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    localStorage.removeItem("subActivityId");
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.onload = (_event) => {
        setImage(reader.result?.toString() || "");
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setImageModal(true);
  };

  const ImageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", Image_File_tye.ACTIVITY);
    formData.append("file", file);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.baseUrl + data.payload.imageUrl);
        setImageModal(false);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  /**
   * edit Slider Master function call
   */

  const editHandle = async (
    activityMasterId,
    image,
    name,
    alt,
    link,
    date,
    details,
    templeId,
    type
  ) => {
    setActivityMasterId(activityMasterId);
    setImage(image);
    setName(name);
    setAlt(alt);
    setLink(link);
    setDate(moment(date).format("YYYY-MM-DD"));
    setDetails(details);
    setTempleId(templeId);
    setType(type);
    setToggle(false);
    setIsAddHandle(false);
    seteditIndex(activityMasterId);
    setModal(true);
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setImage("");
    setName("");
    setAlt("");
    setDate("");
    setActivityMasterId("");
    setDetails("");
    setTempleId("");
    setType("");
    setLink("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setImage(image);
    setName(name);
    setAlt(alt);
    setDate(date);
    setActivityMasterId(activityMasterId);
    setDetails(details);
    setTempleId(templeId);
    setType(type);
    setLink(link);
    setLoaderShow(false);
  };

  /**
   * create and edit function call SLider Master
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;

    if (editIndex > -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);

      formData = {
        activityId: activityId || localStorage.getItem("activityId"),
        image: image ? image : "",
        link: link ? link : image,
        name,
        alt,
        details,
        type,
      };

      try {
        const { data } = await Edit_Sub_Activiti(formData, activityMasterId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        activityId: activityId || localStorage.getItem("activityId"),
        image: image ? image : "",
        link: link ? link : image,
        name,
        alt,
        details,
        type,
      };

      try {
        const { data } = await Add_Sub_Activiti(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (activityMasterId) => {
    setActivityMasterId(activityMasterId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Sub_Activiti_Details(activityMasterId);
      if (data.status === 200) {
        setahoeDataModel(true);
        let payload = data.payload.data;
        setLink(payload ? payload.link : "");
        setName(payload ? payload.name : "");
        setDate(payload ? moment(payload.date).format("DD-MM-YYYY") : "");
        setDetails(payload ? payload.details : "");
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteHandle = async (activityMasterId) => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Sub_Activiti_Delete(activityMasterId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (subActivityId, status) => {
    let formData = {
      subActivityId: subActivityId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Sub_Activiti_Enable_Desable(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  const redirectToAddSubToSubActivity = (subActivityId) => {
    setSubActivityId(subActivityId);
    localStorage.setItem("subActivityId", subActivityId);
    history(
      `${process.env.PUBLIC_URL}/SubToSubActivity/SubToSubActivity/${layout}`
    );
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <SubActivityAddModal
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
        />
        <SubActivityItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          deleteHandle={deleteHandle}
          redirectToAddSubToSubActivity={redirectToAddSubToSubActivity}
        />

        <ChropeImage
          ImageUpload={ImageUpload}
          imageWidth={66}
          aspect={16 / 9}
          imageModal={imageModal}
          setImageModal={setImageModal}
          image={image}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
        />
      </div>
    </>
  );
};
export default SubActivity;
