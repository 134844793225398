import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, TYPE_MASTER } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import TypeMasterContext from "../../context/TypeMasterContext/TypeMasterContext";

const TypeMasterAddModal = (props) => {
  const { onSubmit, closeModel } = props;

  const { modal, togglesubmit, name, setName } = useContext(TypeMasterContext);

  const { loaderShow } = useContext(LoaderContext);

  return (
    <>
      <Modal size="xl" isOpen={modal}>
        <ModalHeader toggle={() => closeModel()}>
          {togglesubmit ? (
            <span>{TYPE_MASTER.ADD_ACTIVITI}</span>
          ) : (
            <span>
              {TYPE_MASTER.EDIT_ACTIVITI} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          {/* <LoaderResponse /> */}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {TYPE_MASTER.ACTIVITI_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">
                          {TYPE_MASTER.NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TypeMasterAddModal;
