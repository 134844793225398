import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ACTIVITI_Master, Activites, BUTTON, TEMPLE } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import ActivitiModualContext from "../../context/ActivitiModualContext/ActivitiModualContext";

const ActivitiModualAddModal = (props) => {
  const { onSubmit, closeModel, readUrl } = props;

  const {
    modal,
    togglesubmit,
    name,
    setName,
    alt,
    setAlt,
    date,
    setDate,
    details,
    setDetails,
    templeId,
    setTempleId,
    type,
    setType,
    image,
  } = useContext(ActivitiModualContext);

  const { loaderShow } = useContext(LoaderContext);

  return (
    <>
      <Modal size="xl" isOpen={modal}>
        <ModalHeader toggle={() => closeModel()}>
          {togglesubmit ? (
            <span>{ACTIVITI_Master.ADD_ACTIVITI}</span>
          ) : (
            <span>
              {ACTIVITI_Master.EDIT_ACTIVITI} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          {/* <LoaderResponse /> */}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {ACTIVITI_Master.ACTIVITI_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {"(1600PX) x (1066PX)"}
                        </Label>
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={image}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                accept="image/*"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  top: "0",
                                  height: "11rem",
                                  width: "11rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ACTIVITI_Master.TEMPLE}
                        </Label>
                        <select
                          className="form-select"
                          name="state"
                          value={templeId}
                          onChange={(e) => setTempleId(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {TEMPLE.map((elem) => {
                            return (
                              <option value={elem.type}>{elem.name}</option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>

                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ACTIVITI_Master.DATE}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="date"
                          placeholder="Date"
                          onChange={(e) => setDate(e.target.value)}
                          value={date}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ACTIVITI_Master.NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md="4 mb-4">
                        <Label htmlFor="validationCustom14">
                          {`${ACTIVITI_Master.IMAGE} (1600 x 1060 px)`}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="file"
                          placeholder="Image"
                          onChange={(e) => readUrl(e)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col> */}
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ACTIVITI_Master.ALT}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Alt"
                          value={alt}
                          onChange={(e) => setAlt(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ACTIVITI_Master.ACTIVITY_TYPE}
                        </Label>
                        <select
                          className="form-select"
                          name="state"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {Activites.map((elem) => {
                            return (
                              <option value={elem.type}>{elem.name}</option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ACTIVITI_Master.DETAILS}
                        </Label>
                        <textarea
                          className="form-control"
                          name="details"
                          id="detailss"
                          cols="30"
                          rows="5"
                          onChange={(e) => setDetails(e.target.value)}
                          value={details}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ActivitiModualAddModal;
