import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Activites, BUTTON, SUB_ACTIVITI } from "../../../constant";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import SubActivityContext from "../../../context/SubActivityContext/SubActivityContext";

const ActivitiModualAddModal = (props) => {
  const { onSubmit, closeModel, readUrl } = props;
  const {
    modal,
    togglesubmit,
    name,
    setName,
    alt,
    setAlt,
    type,
    setType,
    link,
    setLink,
    image,
  } = useContext(SubActivityContext);
  const { loaderShow } = useContext(LoaderContext);

  return (
    <>
      <Modal size="xl" isOpen={modal}>
        <ModalHeader toggle={() => closeModel()}>
          {togglesubmit ? (
            <span>{SUB_ACTIVITI.SUB_ADD_ACTIVITI}</span>
          ) : (
            <span>
              {SUB_ACTIVITI.EDIT_SUB_ACTIVITI} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          {/* <LoaderResponse /> */}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {SUB_ACTIVITI.SUB_ACTIVITI_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    {+type === 1 && (
                      <Row>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom14">
                            {"(1440PX) x (1440PX)"}
                          </Label>
                          <div className="user-image">
                            <div className="avatar">
                              <Media
                                body
                                alt=""
                                src={image}
                                style={{
                                  width: "9rem",
                                  height: "9rem",
                                  borderRadius: "45rem",
                                  border: "1px solid ",
                                }}
                                data-intro="This is Profile image"
                              />
                            </div>
                            <div
                              className="icon-wrapper"
                              data-intro="Change Profile image here"
                            >
                              <i className="icofont icofont-pencil-alt-5">
                                <Input
                                  className="upload"
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => readUrl(e)}
                                  style={{
                                    top: "0",
                                    height: "11rem",
                                    width: "11rem",
                                  }}
                                  // required
                                />
                              </i>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {SUB_ACTIVITI.TYPE}
                        </Label>
                        <select
                          className="form-select"
                          name="state"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        >
                          <option value="">--select--</option>
                          {Activites.map((elem) => {
                            return (
                              <option value={elem.type}>{elem.name}</option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {SUB_ACTIVITI.NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {+type === 1 ? (
                        // <Col md="6 mb-4">
                        //   <Label htmlFor="validationCustom14">
                        //     {`${SUB_ACTIVITI.IMAGE} (1440 x 1440 px)`}
                        //   </Label>
                        //   <Input
                        //     className="form-control"
                        //     name="name"
                        //     id="validationCustom14"
                        //     type="file"
                        //     placeholder="Image"
                        //     onChange={(e) => readUrl(e)}
                        //   />
                        //   <div className="invalid-feedback">
                        //     {"Please provide a valid Admin Name."}
                        //   </div>
                        // </Col>
                        <></>
                      ) : (
                        +type === 2 && (
                          <Col md="6 mb-4">
                            <Label htmlFor="validationCustom14">
                              {SUB_ACTIVITI.LINK}
                            </Label>
                            <Input
                              className="form-control"
                              name="name"
                              id="validationCustom14"
                              type="url"
                              placeholder="Link"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                            />
                            <div className="invalid-feedback">
                              {"Please provide a valid Admin Name."}
                            </div>
                          </Col>
                        )
                      )}
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {SUB_ACTIVITI.ALT}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Alt"
                          value={alt}
                          onChange={(e) => setAlt(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ActivitiModualAddModal;
