import axios from "axios";
import {
  ADD_SLIDER,
  EDIT_SLIDER,
  FILE,
  SLIDER_DELETE,
  SLIDER_DITAILS,
  SLIDER_ENABLE_DESABLE,
  SLIDER_LIST,
} from "../../constant/API constant";

export const Slider_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SLIDER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Slider_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${SLIDER_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Slider = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${ADD_SLIDER}`, formData, {
    headers: { Authorization: jwt_token },
  });
};

export const Edit_Slider = (formData, sliderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${EDIT_SLIDER}`, formData, {
    headers: { Authorization: jwt_token },
    params: { sliderId },
  });
};

export const Slider_Details = (sliderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SLIDER_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { sliderId },
  });
};
export const Slider_Delete = (sliderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${SLIDER_DELETE}`, {
    headers: { Authorization: jwt_token },
    params: { sliderId },
  });
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
