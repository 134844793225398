import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import SliderMasterContext from "../../context/SliderMasterContext/SliderMasterContext";

const OrganizationDetailsModel = (props) => {
  const { ahoeDataModel, setahoeDataModel, image, name, alt } =
    useContext(SliderMasterContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Slider Master
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <CardHeader
                          className="flex-shrink-1"
                          style={{ display: "flex", padding: "0" }}
                        >
                          <th>
                            <a href={image} target="_blank" rel="noreferrer">
                              <Media
                                body
                                alt=""
                                src={image}
                                style={{
                                  width: "5rem",
                                  height: "5rem",
                                  borderRadius: "45rem",
                                  border: "1px solid #f0f0f0",
                                }}
                                data-intro="This is Profile image"
                              />
                            </a>
                          </th>
                          <a
                            href={image}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            View Image
                          </a>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{"Name"}</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Alt"}</th>
                            <td>{alt}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
