export const randomStr = (length) => {
  let randomText = "";
  const randomString =
    "ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  for (let i = 0; i < length; i++) {
    randomText += randomString.charAt(
      Math.floor(Math.random() * randomString.length)
    );
  }
  return randomText;
};
