export const Location = "Location";

export const New = "New";

export const TodayTotalSale = "Today Total sale";

export const Name = "Name";

export const ContactUs = "Contact Us";
export const Email = "Email";

export const Phone = "Phone";
export const EmailAddress = "Email Address";

export const Password = "Password";
export const LogOut = "Log Out";

export const Pagi_Nations = "Pagination";
export const PaginationWithIcons = "Pagination with icons";
export const PaginationAlignment = "Pagination alignment";
export const PaginationActiveDisabled = "Pagination with active and disabled";
export const PaginationColor = "Pagination Color";
export const PaginationSizing = "Pagination sizing";
export const Previous = "Previous";
export const Next = "Next";

// pages
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const CreateAccount = "Create Account";
export const AUTH0 = "AUTH0";
export const JWT = "JWT";
export const LoginWithJWT = "Login";

// layout
export const Loading = "Loading...";

export const Admin = "Admin";
export const Account = "Account";

export const IMAGE_URL =
  "https://mcode.fra1.digitaloceanspaces.com/byte-nfc/bytenfc.png";

export const DATA_NOT_FOUND =
  "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=900&t=st=1667466035~exp=1667466635~hmac=fb00d8e365338a2998c49c4661d0abb17de576ce3efd265331b8a98d6877bc5b";

export const ENDPOINT_IMAGE = "/bagasara-dham/public/25551684995212998.png";

// <<<<<<<<<<<<<<<--------------USER ROLE WISE ACCESS------------------->>>>>>>>>>>>>

export const USER_ROLE = {
  ADMIN: 1,
  SELLER: 2,
};

export const FILE_TYPE = {
  WALLET: 5,
};

export const NFC_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const QR_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const ORDER_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};

export const ORDER_TYPE = {
  BUY: 1,
  SELL: 2,
};

export const Role = [
  {
    id: 1,
    name: "super admin",
  },
  {
    id: 2,
    name: "organization admin",
  },
];

export const Image_File_tye = {
  SLIDER: 1,
  DAILY_DARSAN: 2,
  EVENT: 3,
  ACTIVITY: 4,
  PUBLIC: 5,
  OUR_SAINT: 6,
};

export const DailyDarshanImageUri =
  "https://mcode.fra1.digitaloceanspaces.com/bagasara-dham/";

/**
 * Button Name constant
 */

export const BUTTON = {
  SAVE: "Save",
  SUBMIT: "Submit",
  DOWNLOADE: "Download",
  DONE: "Done",
};

/**
 * Nfc list constant
 */

export const NFC_LIST = {
  NFCLIST: "NFC List",
  QR_SCAN: "QR Scan",
  DOWNLOADE: "Download",
  SELECT_STATUS: "Select Status",
  SAVE: "Save",
};

/**
 * Nfc Order List Constant
 */

export const NFC_ORDER_LIST = {
  PERENT: "Order List",
  EDIT_TITLE: "Edit Title",
  TITLE: "Title",
  ORDER_LIST: "Order List",
  IS_SAME: "Is Same",
  QTY: "QTY",
  CREATE_NFC: "Create NFC",
};

/**
 * Order List Constant
 */

export const ORDER_LIST = {
  PERENT: "Order List",
  GENERATE: "Generate",
  REGENERATE: "ReGenerate",
  EDIT_TITLE: "Edit Title",
  TITLE: "Title",
  CREATE_QR: "Create QR",
  QTY: "QTY",
};

/**
 * order status master constant
 */

export const ORDER_STATUS_MASTER = {
  PERENT: "Order Status Master",
  ADD_ORDER_STATUS: "Add Order Status",
  EDIT_ORDER_STATUS: "Edit Order Status",
  NAME: "Name",
};

export const DAILY_DARSHAN = {
  PERENT: "Daily Darshan",
  ADD_DAILY_DARSHAN: "Add Daily Darshan",
  EDIT_DAILY_DARSHAN: "Edit Daily Darshan",
  DAILY_DARSHAN_DETAILS: "Daily Darshan Details",
  TYPE: "Type",
  TEMPLE: "Temple",
  IMAGE: "Image",
  VIDEO: "Video",
  NAME: "Name",
  ALT: "Alt",
  DATE: "Date",
};

export const PARIVAR_SABHA = {
  PERENT: "Parivar Sabha",
  ADD_PARIVAR_SABHA: "Add Parivar Sabha",
  EDIT_PARIVAR_SABHA: "Edit Parivar Sabha",
  PARIVAR_SABHA_DETAILS: "Parivar Sabha Details",
  LINK: "Link",
  NAME: "Name",
  DATE: "Date",
  DETAILS: "Details",
};
export const EVENTS_Master = {
  PERENT: "Events Master",
  ADD_EVENTS: "Add Events Master",
  EDIT_EVENTS: "Edit Events Master",
  EVENTS_DETAILS: "Events Details Master",
  IMAGE: "Image",
  NAME: "Name",
  DATE: "Date",
  ALT: "Alt",
  TEMPLE: "Temple",
  CATEGORY: "Category",
  DETAILS: "Details",
};

export const ACTIVITI_Master = {
  PERENT: "Activity Master",
  ADD_ACTIVITI: "Add Activity Master",
  EDIT_ACTIVITI: "Edit Activity Master",
  ACTIVITI_DETAILS: "Activity Details Master",
  IMAGE: "Image",
  NAME: "Name",
  DATE: "Date",
  ALT: "Alt",
  DETAILS: "Details",
  ACTIVITY_TYPE: "Activity Type",
  TEMPLE: "Temple",
};

export const TYPE_MASTER = {
  PERENT: "Type Master",
  ADD_ACTIVITI: "Add Type Master",
  EDIT_ACTIVITI: "Edit Type Master",
  ACTIVITI_DETAILS: "Type Master Details",
  NAME: "Name",
};

export const ACTIVITI = {
  PERENT: "Activity",
  ADD_ACTIVITI: "Add Activity",
  EDIT_ACTIVITI: "Edit Activity",
  ACTIVITI_DETAILS: "Activity Details",
  IMAGE: "Image",
  NAME: "Name",
  IMAGE_URI: "Image Uri",
  LINK: "Link",
  ALT: "Alt",
  TEMPLE: "Temple",
  TYPE: "Type",
};

export const SUB_ACTIVITI = {
  PERENT: "Sub Activity",
  SUB_ADD_ACTIVITI: "Add Sub Activity",
  EDIT_SUB_ACTIVITI: "Edit Sub Activity",
  SUB_ACTIVITI_DETAILS: "Sub Activity Details",
  IMAGE: "Image",
  NAME: "Name",
  IMAGE_URI: "Image Uri",
  LINK: "Link",
  ALT: "Alt",
  TEMPLE: "Temple",
  TYPE: "Type",
};
export const SUB_TO_SUB_ACTIVITI = {
  PERENT: "Sub-to-sub Activity",
  SUB_TO_SUB_ADD_ACTIVITI: "Add Sub-to-sub Activity",
  EDIT_SUB_TO_SUB_ACTIVITI: "Edit Sub-to-sub Activity",
  SUB_TO_SUB_ACTIVITI_DETAILS: "Sub-to-sub Activity Details",
  IMAGE: "Image",
  NAME: "Name",
  IMAGE_URI: "Image Uri",
  LINK: "Link",
  ALT: "Alt",
  TEMPLE: "Temple",
  TYPE: "Type",
};
export const EVENT = {
  PERENT: "Events",
  ADD_EVENTS: "Add Events",
  EDIT_EVENTS: "Edit Events",
  EVENTS_DETAILS: "Events Details",
  IMAGE: "Image",
  NAME: "Name",
  IMAGE_URI: "Image Uri",
  LINK: "Link",
  ALT: "Alt",
  TEMPLE: "Temple",
  TYPE: "Type",
};

export const SLIDER_MASTER = {
  PERENT: "Slider Master",
  ADD_SLIDE: "Add Slider",
  EDIT_SLIDE: "Edit Slider",
  SLIDE_DETAILS: "Slider Details",
  IMAGE: "Image",
  NAME: "Name",
  ALT: "Alt",
};

export const WHITE_LABEL_DOMAIN_CONSTANT = {
  PERENT: "White Label Domain",
  ADD_WHITE_LABEL_DOAMIN: "Add White Label Domain",
  EDIT_WHITE_LABEL_DOAMIN: "Edit White Label Domain",
  WHITE_LABEL_DOMAIN_DETAILS: "White Label Domain Details",
  WHITE_LABEL_DOMAIN: "White Label Domain",
  NAME: "Name",
  GST_IN: "GST In",
  LOGO: "Logo",
  FAVICON: "Favicon",
  URL: "Url",
};

/**
 * pachages constant
 */
export const PACKAGES = {
  PERENT: "Packages",
  ADD_PACKAGE: "Add Packages",
  EDIT_PACKAGES: "Edit Packages",
  NAME: "Name",
  PRICE: "Price",
  GST: "GST",
  QTY: "QTY",
  TYPE: "Type",
  NFC: "NFC",
  QR: "Qr",
  BENEFIT: "Benefit",
};

/**
 * privillage constant
 */
export const PRIVILLAGE = {
  PERENT: "Privillage",
  ADD_PREVILLAGE: "Add Privillage",
  EDIT_PRIVILLAGE: "Edit Privillage",
  PRIVILALGE_DETAILS: "Privillage Details",
  NAME: "Name",
  METHOD: "Method",
  URLS: "URLs",
  PERENT_MODULES: "Parent Modules",
  CJILD_MODULE: "Child Module",
  MODULE_ACCESS: "Module Access",
  VIEW_PRIVILLAGE: "View Privillage",
  MODULE_NAME: "Module name",
  MODULE_ACCESSNAME: "Module AccessName",
};

/**
 * qr list constant
 */

export const QR_LIST = {
  PERENT: "QR List",
  ORDER_LIST: "OrderList",
  QR_SCAN: "QR Scan",
};

/**
 * qr list constant
 */

export const WALLET_LIST = {
  PERENT: "Wallet",
};

/**
 * seller forget password constant
 */

export const CHANGE_PASSWORD = {
  CHNAGEPASSWORD: "Change Password",
  OLD_PASSWORD: "Old Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
};

/**
 * Shipping master constant
 */

export const SHIPPING_MASTER = {
  PERENT: "Shopping Master",
  ADD_SHIPPING_MASTER: "Add Shipping Master",
  EDIT_SHIPPING_MASTER: "Edit Shipping Master",
  COMPANY_NAME: "Company Name",
  LINK: "Link",
};

/**
 * user profile constant
 */

export const USER_PROFILE = {
  PERENT: "Users",
  TITLE: "User Profile",
  EMAIL: "Email",
  NAME: "Name",
  STATE: "State",
  ORG_DETAILS: "Organization Details",
  CITY: "City",
  CONTACT_US: "Contact Us",
  LOCATION: "Location",
  ADMIN_DETAILS: "Admin Details",
};

/**
 * Users constant
 */

export const USERS = {
  PERENT: "User",
};

export const MuiTabelStyle = {
  ".MuiTablePagination-selectLabel": {
    margin: "0px",
  },
  ".MuiTablePagination-displayedRows": {
    margin: "0px",
  },

  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};

/**
 * daily darshan type and temple
 */

export const TYPES = [
  {
    type: 1,
    name: "Video",
  },
  {
    type: 2,
    name: "Image",
  },
];

export const TEMPLE = [
  {
    type: 1,
    name: "Bagasara",
  },
  {
    type: 2,
    name: "Khopala",
  },
];

export const CategorysId = [
  {
    name: "Event",
    id: 1,
  },
  {
    name: "Activity",
    id: 2,
  },
];

export const Activites = [
  {
    type: 2,
    name: "Videos",
  },
  // { type: 5, name: "Mantra Lekhan" },
  // { type: 3, name: "Audio" },
  {
    type: 1,
    name: "Photos",
  },
  // { type: 4, name: "Download" },
];
