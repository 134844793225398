import {
  Home,
  Key,
  Sliders,
  Sunrise,
  Users,
  Smile,
  Activity,
  List,
} from "react-feather";

let admin = `Hello, Admin`;

export const MENUITEMS = [
  {
    menutitle: admin,

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/TypeMaster/TypeMaster`,
      //   icon: List,
      //   title: "Type Master",
      //   type: "link",
      // },
      {
        path: `${process.env.PUBLIC_URL}/SliderMaster/SliderMaster`,
        icon: Sliders,
        title: "Slider Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/DailyDarshanMaster/DailyDarshanMaster`,
        icon: Sunrise,
        title: "Daily Darshan",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/ParivarSabhaMaster/ParivarSabhaMaster`,
        icon: Users,
        title: "Parivar Sabha",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/EventsModual/EventModual`,
        icon: Smile,
        title: "Events",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/ActivitiModual/ActivitiModual`,
        icon: Activity,
        title: "Activitis",
        type: "link",
      },
      {
        title: "Roles & Privillages",
        icon: Key,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager`,
            title: "Privillage Manager",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager`,
            title: "Module Manager",
            type: "link",
          },
        ],
      },
    ],
  },
];
