import React, { useContext, useEffect, useState } from "react";

import DailyDarshanMasterItem from "./DailyDarshanMasterItem";
import DailyDarshanMasterDetailsModel from "./DailyDarshanMasterDetailsModel";
import DailyDarshanMasterAddModal from "./DailyDarshanMasterAddModal";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { Image_File_tye } from "../../constant";
import DailyDarshanContext from "../../context/DailyDarshanMasterContext/DailyDarshanMasterContext";
import {
  Daily_Darshan_List,
  FILE_API,
  Daily_Darshan_Enable_Desable,
  Add_Daily_Darshan,
  Daily_Darshan_Details,
  Edit_Daily_Darshan,
  Daily_Darshan_Delete,
} from "../../api/Daily Darshan Api/DailyDarshanApi";
import moment from "moment";
import ChropeImage from "../SliderMaster/ChropeImage";

const DailyDarshanMaster = () => {
  const {
    setformvalue,
    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    isSwitchOn,
    setIsSwitchOn,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    setBaseUrl,
    setImage,
    image,
    name,
    alt,
    setType,
    setTempleId,
    setDate,
    setAlt,
    setName,
    type,
    templeId,
    baseUrl,
    date,
    dailyDarshanId,
    setDailyDarshanId,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    imageModal,
    setImageModal,
  } = useContext(DailyDarshanContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * Slider Master list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await Daily_Darshan_List(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.onload = (_event) => {
        setImage(reader.result?.toString() || "");
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setImageModal(true);
  };

  const ImageUpload = async (file) => {
    setLoaderShow(true);
    let formData = new FormData();
    formData.append("type", Image_File_tye.DAILY_DARSAN);
    formData.append("file", file);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setImage(data.payload.baseUrl + data.payload.imageUrl);
        setImageModal(false);
        setLoaderShow(false);
      } else {
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  /**
   * edit Slider Master function call
   */

  const editHandle = async (dailyDarshanId) => {
    setDailyDarshanId(dailyDarshanId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Daily_Darshan_Details(dailyDarshanId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(dailyDarshanId);
        setModal(true);

        let payload = data.payload.data;

        setImage(payload.link);
        setType(payload.type);
        setTempleId(payload.templeId);
        setName(payload.name);
        setAlt(payload.alt);
        setDate(moment(payload.date).format("YYYY-MM-DD"));

        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setImage("");
    setType("");
    setTempleId("");
    setName("");
    setAlt("");
    setBaseUrl("");
    setDailyDarshanId("");
    setDate("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setImage(image);
    setType(type);
    setTempleId(templeId);
    setName(name);
    setAlt(alt);
    setBaseUrl(baseUrl);
    setDailyDarshanId(dailyDarshanId);
    setDate(date);
    setLoaderShow(false);
  };

  /**
   * create and edit function call SLider Master
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;

    if (editIndex > -1 && !togglesubmit) {
      debugger;
      setLoaderShow(!loaderShow);
      let logo = image.split("bagasara-dham/").pop();
      formData = {
        type,
        link: +type === 1 ? image : logo,
        templeId,
        name,
        date,
        alt,
      };

      try {
        const { data } = await Edit_Daily_Darshan(formData, dailyDarshanId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      let logo = image.split("bagasara-dham/").pop();
      formData = {
        type,
        link: logo,
        templeId,
        name,
        date,
        alt,
      };

      try {
        const { data } = await Add_Daily_Darshan(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (dailyDarshanId) => {
    setDailyDarshanId(dailyDarshanId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Daily_Darshan_Details(dailyDarshanId);
      if (data.status === 200) {
        setahoeDataModel(true);
        let payload = data.payload.data;
        setImage(payload.link);
        setType(payload.type);
        setTempleId(payload.templeId);
        setName(payload.name);
        setAlt(payload.alt);
        setDate(payload.date);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const deleteHandle = async (dailyDarshanId) => {
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Daily_Darshan_Delete(dailyDarshanId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (dailyDarshanId, status) => {
    let formData;
    formData = {
      dailyDarshanId: dailyDarshanId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Daily_Darshan_Enable_Desable(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <DailyDarshanMasterAddModal
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
        />
        <DailyDarshanMasterDetailsModel />
        <DailyDarshanMasterItem
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          deleteHandle={deleteHandle}
        />
        <ChropeImage
          ImageUpload={ImageUpload}
          imageWidth={10000}
          aspect={9 / 16}
          imageModal={imageModal}
          setImageModal={setImageModal}
          image={image}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
        />
      </div>
    </>
  );
};
export default DailyDarshanMaster;
