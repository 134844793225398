import React from "react";
import Routers from "./route";
import { Provider } from "react-redux";
import store from "./store";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { auth0 } from "./data/config";
import AdminDeshboardState from "./context/AdminDeshboardContext/AdminDeshboardState";
import ShippingMasterState from "./context/ShippingMasterContext/ShippingMasterState";
import PrivillageMasterState from "./context/PrivillageMasterContext/PrivillageMasterState";
import ModualManagerState from "./context/ModuleManagerContext/ModualManagerState";
// import LoaderResponse from "./Reuseable/Loader";
import LoaderState from "./context/LoaderContext/LoaderState";
import SliderMasterState from "./context/SliderMasterContext/SliderMasterState";
import DalyDarshanState from "./context/DailyDarshanMasterContext/DailyDarshanMasterState";
import ParivarSabhaState from "./context/ParivarSabhaMasterContext/ParivarSabhaMasterState";
import EventModualState from "./context/EventModualContext/EventModualState";
import EventMasterDataState from "./context/EventMasterDataContext/EventMasterDataState";
import ActivitiModualState from "./context/ActivitiModualContext/ActivitiModualState";
import ActivitiMasterDataState from "./context/ActivitiMasterDataContext/ActivitiMasterDataState";
import SubActivityState from "./context/SubActivityContext/SubActivityState";
import SubToSubActivityState from "./context/SubToSubActivityContext/SubActivityState";
import TypeMasterState from "./context/TypeMasterContext/TypeMasterState";

const App = () => (
  <div className="App">
    {/* <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      redirectUri={auth0.redirectUri}
    > */}
    <Provider store={store}>
      <ModualManagerState>
        <PrivillageMasterState>
          <LoaderState>
            {/* <LoaderResponse /> */}
            <TypeMasterState>
              <SubToSubActivityState>
                <SubActivityState>
                  <ActivitiMasterDataState>
                    <ActivitiModualState>
                      <EventMasterDataState>
                        <EventModualState>
                          <ParivarSabhaState>
                            <DalyDarshanState>
                              <SliderMasterState>
                                <ShippingMasterState>
                                  <AdminDeshboardState>
                                    <Routers />
                                  </AdminDeshboardState>
                                </ShippingMasterState>
                              </SliderMasterState>
                            </DalyDarshanState>
                          </ParivarSabhaState>
                        </EventModualState>
                      </EventMasterDataState>
                    </ActivitiModualState>
                  </ActivitiMasterDataState>
                </SubActivityState>
              </SubToSubActivityState>
            </TypeMasterState>
          </LoaderState>
        </PrivillageMasterState>
      </ModualManagerState>
    </Provider>
    {/* </Auth0Provider> */}
  </div>
);

export default App;
