import axios from "axios";
import {
  ACTIVITI_DELETE,
  ACTIVITI_DETAILS,
  ACTIVITI_EDIT,
  ACTIVITI_ENABLE_DESABLE,
  ACTIVITI_LIST,
  ADD_ACTIVITI,
  FILE,
} from "../../constant/API constant";

export const Activiti_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ACTIVITI_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Activiti_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ACTIVITI_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Activiti = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ACTIVITI}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Activiti = (formData, activityMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ACTIVITI_EDIT}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { activityMasterId },
    }
  );
};

export const Activiti_Details = (activityMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ACTIVITI_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { activityMasterId },
  });
};

export const Activiti_Delete = (activityMasterId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(`${process.env.REACT_APP_API_URL}${ACTIVITI_DELETE}`, {
    headers: { Authorization: jwt_token },
    params: { activityMasterId },
  });
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
