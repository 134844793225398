import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import DailyDarshanContext from "../../context/DailyDarshanMasterContext/DailyDarshanMasterContext";
import moment from "moment";
import { DailyDarshanImageUri } from "../../constant";

const OrganizationDetailsModel = (props) => {
  const {
    ahoeDataModel,
    setahoeDataModel,
    image,
    name,
    alt,
    type,
    date,
    templeId,
  } = useContext(DailyDarshanContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Slider Master
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <CardHeader
                          className="flex-shrink-1"
                          style={{ display: "flex", padding: "17px" }}
                        >
                          {+type === 2 && (
                            <th>
                              <a
                                href={DailyDarshanImageUri + image}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Media
                                  body
                                  alt=""
                                  src={DailyDarshanImageUri + image}
                                  style={{
                                    width: "5rem",
                                    height: "5rem",
                                    borderRadius: "45rem",
                                    border: "1px solid #f0f0f0",
                                  }}
                                  data-intro="This is Profile image"
                                />
                              </a>
                            </th>
                          )}
                          <a
                            href={image}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {+type === 2 ? "View Image" : "View Video"}
                          </a>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{"Date"}</th>
                            <td>{moment(date).format("DD-MM-YYYY")}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"name"}</th>
                            <td>{name ? name : "-"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Alt"}</th>
                            <td>{alt ? alt : "-"}</td>
                          </tr>
                          <tr>
                            <th scope="row">{"Temple"}</th>
                            <td>{+templeId === 1 ? "Bagasara" : "Khopala"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationDetailsModel;
