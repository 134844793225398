import axios from "axios";
import {
  ADD_PARIVAR_SABHA,
  Edit_PARIVAR_SABHA,
  FILE,
  PARIVAR_SABHA_DELETE,
  PARIVAR_SABHA_DETAILS,
  PARIVAR_SABHA_ENABLE_DESABLE,
  PARIVAR_SABHA_LIST,
} from "../../constant/API constant";

export const Parivar_Sabha_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PARIVAR_SABHA_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Parivar_Sabha_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PARIVAR_SABHA_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Parivar_Sabha = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_PARIVAR_SABHA}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Parivar_Sabha = (formData, sabhaId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${Edit_PARIVAR_SABHA}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { sabhaId },
    }
  );
};

export const Parivar_Sabha_Details = (sabhaId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PARIVAR_SABHA_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { sabhaId },
  });
};

export const Privar_Sabha_Delete = (sabhaId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${PARIVAR_SABHA_DELETE}`,
    {
      headers: { Authorization: jwt_token },
      params: { sabhaId },
    }
  );
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
