import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, User, Maximize, Unlock, Lock } from "react-feather";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../../data/config";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { Admin, Account, LogOut, USER_ROLE } from "../../constant";
import { classes } from "../../data/layouts";
import SellerForgerPassword from "../../components/SellerForgetPassword/SellerForgerPassword";
import { Email } from "@material-ui/icons";

const Rightbar = () => {
  const history = useNavigate();
  const [profile] = useState(localStorage.getItem("profileURL") || man);
  const [name, setName] = useState("");
  const [moonlight, setMoonlight] = useState(false);
  const { i18n } = useTranslation();

  const [modal, setModal] = useState(false);
  const [changePssmodal, setChangePassModal] = useState(false);
  const [changeEmailmodal, setChangeEmailModal] = useState(false);

  const { logout } = useAuth0();
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  useEffect(async () => {
    setName(localStorage.getItem("name"));

    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
    i18n.changeLanguage("en");
  }, []);

  const Logout_From_Firebase = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("roleId");
    localStorage.removeItem("orgId");
    localStorage.removeItem("orgIdGenQr");
    firebase_app.auth().signOut();
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const Logout_From_Auth0 = () => {
    localStorage.removeItem("auth0_profile");
    localStorage.setItem("authenticated", false);
    history(`${process.env.PUBLIC_URL}/login`);
    logout();
  };
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const navigate = useNavigate();

  const userProfileRedirect = async () => {
    navigate(`${process.env.PUBLIC_URL}/userProfile/userProfile/${layout}`);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.classList.add("light");
      document.body.classList.remove("dark-only");
      // document.body.className = "light"
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.classList.remove("light");
      document.body.classList.add("dark-only");
      // document.body.className = "dark-only"
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  const sellerForgetPassword = () => {
    setModal(true);
  };

  return (
    <>
      <Fragment>
        <div className="nav-right col-8 pull-right right-header p-0">
          <ul className="nav-menus">
            <li>
              <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
                {moonlight ? (
                  <i className="fa fa-lightbulb-o"></i>
                ) : (
                  <i className="fa fa-moon-o"></i>
                )}
              </div>
              {/* <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div> */}
            </li>
            <li
              onClick={authenticated ? Logout_From_Auth0 : Logout_From_Firebase}
              style={{ cursor: "pointer" }}
            >
              <LogIn />
            </li>
            {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
              ""
            ) : (
              <li
                onClick={() => sellerForgetPassword()}
                style={{ cursor: "pointer" }}
              >
                <Unlock />
              </li>
            )}

            <li className="maximize">
              <a className="text-dark" href="#javascript" onClick={goFull}>
                <Maximize />
              </a>
            </li>
            <li className="profile-nav onhover-dropdown p-0">
              <div className="media profile-media">
                <img className="b-r-10" src={profile} alt="" />
                <div className="media-body">
                  <span>{authenticated ? auth0_profile.name : name}</span>
                  <p className="mb-0 font-roboto">
                    {Admin} <i className="middle fa fa-angle-down"></i>
                  </p>
                </div>
              </div>
              <ul
                className="profile-dropdown onhover-show-div"
                style={{ width: "12rem" }}
              >
                {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
                  <>
                    <li onClick={() => setChangePassModal(!changePssmodal)}>
                      <Lock />
                      <span>{"Change Password"}</span>
                    </li>
                    <li onClick={() => setChangeEmailModal(!changeEmailmodal)}>
                      <Email />
                      <span>{"Change Org Email"}</span>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={() => userProfileRedirect()}>
                      <User />
                      <span>{Account} </span>
                    </li>
                    <li onClick={() => sellerForgetPassword()}>
                      <Unlock />
                      <span>{"Change Password"} </span>
                    </li>
                  </>
                )}
                <li
                  onClick={
                    authenticated ? Logout_From_Auth0 : Logout_From_Firebase
                  }
                >
                  <LogIn />
                  <span>{LogOut}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Fragment>
      <SellerForgerPassword modal={modal} setModal={setModal} />
    </>
  );
};
// export default translate(Rightbar);
export default Rightbar;
