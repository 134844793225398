import axios from "axios";
import {
  ADD_SUB_TO_SUB_ACTIVITI,
  Edit_SUB_TO_SUB_ACTIVITI,
  FILE,
  SUB_TO_SUB_ACTIVITI_DELETE,
  SUB_TO_SUB_ACTIVITI_DETAILS,
  SUB_TO_SUB_ACTIVITI_ENABLE_DESABLE,
  SUB_TO_SUB_ACTIVITI_LIST,
} from "../../constant/API constant";

export const Sub_To_Sub_Activiti_List = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SUB_TO_SUB_ACTIVITI_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    }
  );
};

export const Sub_To_Sub_Activiti_Enable_Desable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${SUB_TO_SUB_ACTIVITI_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Add_Sub_To_Sub_Activiti = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_SUB_TO_SUB_ACTIVITI}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Edit_Sub_To_Sub_Activiti = (formData, subToSubActivityId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${Edit_SUB_TO_SUB_ACTIVITI}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { subToSubActivityId },
    }
  );
};

export const Sub_To_Sub_Activiti_Details = (subToSubActivityId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${SUB_TO_SUB_ACTIVITI_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { subToSubActivityId },
    }
  );
};

export const Sub_To_Sub_Activiti_Delete = (subToSubActivityId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${SUB_TO_SUB_ACTIVITI_DELETE}`,
    {
      headers: { Authorization: jwt_token },
      params: { subToSubActivityId },
    }
  );
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
